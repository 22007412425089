import MainCategoryServices from '../../common/utils/MainCategoryServices';
import SubCategoryServices from '../../common/utils/SubCategoryServices';
import React from 'react';
import { useState, useEffect } from 'react';
import { get } from 'lodash-es';
import './services-categories.css';

const ServicesCategoriesPanel = () => {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [newCategoryType, setNewCategoryType] = useState('');
    const [newParentCategory, setNewParentCategory] = useState(null);
    const [arabicName, setArabicName] = useState('');
    const [hebrewName, setHebrewName] = useState('');
    const [englishName, setEnglishName] = useState('');
    const [catId, setCatId] = useState('');
    const [bottomColor, setBottomColor] = useState('');
    const [topColor, setTopColor] = useState('');
    const [categoryIcon, setCategoryIcon] = useState('');
    const [categoryIsHidden, setCategoryIsHidden] = useState<boolean>(false);
    const [order, setOrder] = useState('');

    const fetchCategories = async () => {
        try {
            const response = await MainCategoryServices.getAll();
            setCategories(response.data?.data?.categories || []);
        } catch (error: any) {
            console.error('Error fetching categories:', error.response);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (selectedSubCategory) {
            setArabicName(get(selectedSubCategory, 'name.ar'));
            setHebrewName(get(selectedSubCategory, 'name.he'));
            setEnglishName(get(selectedSubCategory, 'name.en'));
            setCategoryIsHidden(get(selectedSubCategory, 'deleted'));
        } else if (selectedCategory) {
            setArabicName(get(selectedCategory, 'name.ar'));
            setHebrewName(get(selectedCategory, 'name.he'));
            setEnglishName(get(selectedCategory, 'name.en'));
            setCatId(get(selectedCategory, '_id'));
            setTopColor(get(selectedCategory, 'topColor'));
            setBottomColor(get(selectedCategory, 'bottomColor'));
            setCategoryIcon(get(selectedCategory, 'icon'));
            setCategoryIsHidden(get(selectedCategory, 'deleted'));
            setOrder(get(selectedCategory, 'order') || '');
        } else {
            setArabicName('');
            setHebrewName('');
            setEnglishName('');
        }
    }, [selectedCategory, selectedSubCategory]);

    const handleCategoryChange = async e => {
        const categoryId = e.target.value;
        const selectedCategory = categories.find(category => category._id === categoryId);

        if (selectedCategory) {
            setSelectedCategory(selectedCategory);
            setSelectedSubCategory(null);
            setCategoryIsHidden(null);
            setOrder(null);
            setArabicName('');
            setHebrewName('');
            setEnglishName('');
            setTopColor('');
            setBottomColor('');
            setCategoryIcon('');

            // Fetch updated sub-categories for the selected main category
            try {
                const response = await SubCategoryServices.getByCategoryId(categoryId);
                setSubCategories(response.data);
            } catch (error) {
                console.error('Failed to fetch sub-categories:', error);
                setSubCategories([]);
            }
        } else {
            // Handle the case when the "Select a category" option is selected
            setSelectedCategory(null);
            setSelectedSubCategory(null);
            setCategoryIsHidden(null);
            setArabicName('');
            setHebrewName('');
            setEnglishName('');
            setTopColor('');
            setBottomColor('');
            setCategoryIcon('');
            setSubCategories([]);
        }
    };

    const handleSubCategoryChange = async e => {
        const subCategoryId = e.target.value;
        const newSubCategory = subCategories.find(subCategory => subCategory._id === subCategoryId);

        if (newSubCategory) {
            setSelectedSubCategory(newSubCategory);
            setCategoryIsHidden(null);
            setArabicName(newSubCategory.name.ar);
            setHebrewName(newSubCategory.name.he);
            setEnglishName(newSubCategory.name.en);
            setTopColor('');
            setBottomColor('');
            setCategoryIcon('');
        } else {
            // Handle the case when the "Select a sub-category" option is selected
            setSelectedSubCategory(null);
            setCategoryIsHidden(null);
            setArabicName('');
            setHebrewName('');
            setEnglishName('');
            setTopColor('');
            setBottomColor('');
            setCategoryIcon('');
        }
    };

    useEffect(() => {
        if (selectedCategory) {
            setSubCategories(subCategories.filter(e => e && e.categoryIds[0] === selectedCategory._id));
        } else {
            setSubCategories([]);
        }
    }, [selectedCategory, categories]);

    const updateCategoryNames = async () => {
        const confirmation = window.confirm('האם אתה בטוח שברצונך לעדכן את הקטגוריה הזו?');
        if (!confirmation) {
            return;
        }

        let selectedType;
        let selectedId;

        if (selectedSubCategory) {
            selectedType = 'subCategory';
            selectedId = selectedSubCategory._id;
        } else {
            selectedType = 'category';
            selectedId = selectedCategory._id;
        }

        const updatedCategory = {
            [selectedType]: {
                ...selectedCategory,
                ...selectedSubCategory,
                name: {
                    ar: arabicName,
                    he: hebrewName,
                    en: englishName,
                },
                icon: categoryIcon,
                topColor: topColor,
                bottomColor: bottomColor,
                order: Number(order),
            },
        };

        try {
            if (selectedType === 'category') {
                await MainCategoryServices.update(selectedId, updatedCategory[selectedType]);
            } else if (selectedType === 'subCategory') {
                await SubCategoryServices.update(selectedId, updatedCategory[selectedType]);
            }

            console.log('Updated category:', updatedCategory[selectedType]);

            // Update the categories state with the updated category

            setCategories(
                categories.map(cat =>
                    cat._id === updatedCategory[selectedType]._id ? updatedCategory[selectedType] : cat,
                ),
            );

            setSubCategories(
                subCategories.map(subCat =>
                    subCat._id === updatedCategory[selectedType]._id ? updatedCategory[selectedType] : subCat,
                ),
            );

            if (selectedType === 'category') {
                setSelectedCategory(updatedCategory[selectedType]);
            } else if (selectedType === 'subCategory') {
                setSelectedSubCategory(updatedCategory[selectedType]);
            }
        } catch (error) {
            console.error('Failed to update category:', error);
        }
    };

    const recoverCategoryOrSubCategory = () => {
        if (selectedSubCategory) {
            recoverSelectedSubCategory();
        } else if (selectedCategory) {
            recoverSelectedCategory();
        }
    };

    const recoverSelectedSubCategory = async () => {
        try {
            const selectedSubCateogryId = selectedSubCategory._id;
            await SubCategoryServices.update(selectedSubCateogryId, {
                ...selectedSubCategory,
                deleted: false,
            });
            setSubCategories(
                subCategories.map(subCat =>
                    subCat._id === selectedSubCateogryId ? { ...subCat, deleted: false } : subCat,
                ),
            );

            setSelectedSubCategory(state => ({ ...state, deleted: false }));
        } catch (error) {
            console.log(error);
        }
    };

    const recoverSelectedCategory = async () => {
        try {
            const selectedCateogryId = selectedCategory._id;
            await MainCategoryServices.update(selectedCateogryId, {
                ...selectedCategory,
                deleted: false,
            });

            setCategories(categories.map(cat => (cat._id === selectedCateogryId ? { ...cat, deleted: false } : cat)));

            setSelectedCategory(state => ({ ...state, deleted: false }));
        } catch (error) {
            console.log(error);
        }
    };

    const addCategory = async () => {
        if (!newCategoryType || (newCategoryType !== 'CATEGORY' && !newParentCategory)) {
            window.alert('נא לבחור סוג קטגוריה וקטגוריית על.');
            return;
        }
        const newCategory: any = {
            name: {
                ar: arabicName,
                he: hebrewName,
                en: englishName,
            },
            icon: categoryIcon,
            topColor: topColor,
            bottomColor: bottomColor,
            order: Number(order),
        };

        if (newCategoryType !== 'CATEGORY') {
            newCategory.categoryIds = [];
            newCategory.categoryIds[0] = newParentCategory;
        }

        console.log('Adding new category:', newCategory);

        try {
            let response;
            if (newCategoryType === 'CATEGORY') {
                response = await MainCategoryServices.create(newCategory);
            } else {
                response = await SubCategoryServices.create(newCategory);
            }

            // Update the subCategories state after adding the new category
            if (newCategoryType === 'SUB_CATEGORY') {
                const addedCategory = response?.data;
                setSubCategories([...subCategories, addedCategory]);
                setSelectedSubCategory(addedCategory);
                setCategories([...categories, addedCategory]);
            } else {
                const addedCategory = response?.data?.data?.category;
                setSelectedCategory(addedCategory);
                setCategories([...categories, addedCategory]);
            }
            // Alert the user and clear input elements and dropdowns
            window.alert('הקטגוריה נוספה בהצלחה.');
            // setArabicName('');
            // setHebrewName('');
            // setEnglishName('');
            // setTopColor('');
            // setBottomColor('');
            // setCategoryIcon('');
            // setNewCategoryType('');
            // setNewParentCategory('');
        } catch (error) {
            console.error('Failed to add category:', error);
        }
    };

    const handleNewCategoryTypeChange = e => {
        setNewCategoryType(e.target.value);
        setNewParentCategory('');
    };

    const handleNewParentCategoryChange = event => {
        const selectedParentId = event.target.value;
        if (selectedParentId) {
            setNewParentCategory(selectedParentId);
        } else {
            setNewParentCategory(null);
        }
    };

    const hasChildCategories = parentId => {
        return subCategories.some(category => category.categoryIds[0] && category.categoryIds[0] === parentId);
    };

    const deleteCategory = async () => {
        const confirmation = window.confirm('האם אתה בטוח שברצונך למחוק את הקטגוריה הזו?');

        if (!confirmation) {
            return;
        }
        let categoryIdToDelete;

        if (selectedSubCategory) {
            categoryIdToDelete = selectedSubCategory._id;
        } else if (selectedCategory) {
            categoryIdToDelete = selectedCategory._id;
        }

        // if (hasChildCategories(categoryIdToDelete)) {
        //     window.alert('לא ניתן למחוק את הקטגוריה מכיוון שיש לה קטגוריות משנה ! ! ! ! ! !');
        //     return;
        // }

        if (selectedSubCategory) {
            try {
                await SubCategoryServices.delete(selectedSubCategory._id);
                console.log('Deleted sub-category:', selectedSubCategory);
                // setSubCategories(subCategories.filter(cat => cat._id !== selectedSubCategory._id));
                setSelectedSubCategory(state => ({ ...state, deleted: true }));
            } catch (error) {
                console.error('Failed to delete sub-category:', error);
            }
        } else if (selectedCategory) {
            try {
                await MainCategoryServices.delete(categoryIdToDelete);
                console.log('Deleted category:', selectedCategory);
                // setCategories(categories.filter(cat => cat._id !== selectedCategory._id));
                // setSelectedCategory(null);
                // setSubCategories([]);
                setSelectedCategory(state => ({ ...state, deleted: true }));
            } catch (error) {
                console.error('Failed to delete category:', error);
            }
        }
    };

    return (
        <div>
            <h1>ניהול קטגוריות שירותים</h1>

            <label htmlFor="category">קטגוריה:</label>
            <select id="category" value={selectedCategory?._id || ''} onChange={handleCategoryChange}>
                <option key="default" value="">
                    בחר מהרשימה
                </option>
                {categories.map(category => (
                    <option key={category._id} value={category._id}>
                        {category.name?.ar} ({category.order})
                    </option>
                ))}
            </select>
            <br />
            <label htmlFor="sub-category">תת-קטגוריה:</label>
            <select
                id="sub-category"
                value={selectedSubCategory?._id || ''}
                onChange={handleSubCategoryChange}
                disabled={!selectedCategory}>
                <option value="">בחר מהרשימה</option>
                {subCategories.map(subCategory => (
                    <option key={subCategory._id} value={subCategory._id}>
                        {subCategory.name?.ar}
                    </option>
                ))}
            </select>
            <br />
            <br />

            <h2>פרטי קטגוריה נבחרה</h2>
            {selectedCategory || selectedSubCategory ? (
                <div>
                    <p>
                        <strong>الاسم (Arabic):</strong>
                        <input
                            type="text"
                            value={arabicName}
                            onChange={e => setArabicName(e.target.value)}
                            style={{ width: '250px' }}
                        />
                    </p>
                    <p>
                        <strong>שם (Hebrew):</strong>
                        <input
                            type="text"
                            value={hebrewName}
                            onChange={e => setHebrewName(e.target.value)}
                            style={{ width: '250px' }}
                        />
                    </p>
                    <p>
                        <strong>שם (English):</strong>
                        <input
                            type="text"
                            value={englishName}
                            onChange={e => setEnglishName(e.target.value)}
                            style={{ width: '250px' }}
                        />
                    </p>
                    {!selectedSubCategory && (
                        <p>
                            <strong>Order:</strong>
                            <input
                                type="text"
                                value={order}
                                onChange={e => setOrder(e.target.value)}
                                style={{ width: '250px' }}
                            />
                        </p>
                    )}
                    <p>
                        <strong>DB _id:</strong>
                        <input type="text" value={catId} style={{ width: '250px' }} />
                    </p>
                    <p>
                        <strong>מופיע?:</strong>
                        {categoryIsHidden === true ? (
                            <div className="categoryishiddentrue">'קטגוריה מוסתרת ! ! !'</div>
                        ) : (
                            <div className="categoryishiddenfalse">קטגוריה מופיעה באפליקציה</div>
                        )}
                    </p>
                    {!selectedSubCategory && (
                        <div className="two-sides">
                            <div className="right-side">
                                <div>
                                    <h4>Top Color</h4>
                                    <input
                                        type="text"
                                        value={topColor}
                                        onChange={e => setTopColor(e.target.value)}
                                        dir="ltr"
                                    />
                                </div>

                                <div>
                                    <h4>Bottom Color</h4>
                                    <input
                                        type="text"
                                        value={bottomColor}
                                        onChange={e => setBottomColor(e.target.value)}
                                        dir="ltr"
                                    />
                                </div>
                                <div>
                                    <h4>Icon</h4>
                                    <input
                                        type="text"
                                        value={categoryIcon}
                                        onChange={e => setCategoryIcon(e.target.value)}
                                        style={{ width: '500px' }}
                                        dir="ltr"
                                    />
                                </div>
                            </div>
                            <div className="left-side">
                                <div
                                    style={
                                        { '--topColor': topColor, '--bottomColor': bottomColor } as React.CSSProperties
                                    }
                                    className="box-container">
                                    <img src={categoryIcon} className="box-image" />
                                    <h1 className="box-title">{arabicName}</h1>
                                </div>
                            </div>
                        </div>
                    )}
                    <p>
                        <strong>סוג קטגוריה נבחרה:</strong>
                        {selectedSubCategory ? 'תת-קטגוריה' : 'קטגוריה'}
                    </p>
                    <div>סוג:</div>
                    <select value={newCategoryType} onChange={handleNewCategoryTypeChange}>
                        <option value="">בחר מהרשימה</option>
                        <option value="CATEGORY">קטגוריה</option>
                        <option value="SUB_CATEGORY">תת-קטגוריה</option>
                    </select>
                    <br />
                    <div>קטגוריית על:</div>
                    {newCategoryType !== 'CATEGORY' && (
                        <select value={newParentCategory || ''} onChange={handleNewParentCategoryChange}>
                            <option key="" value="">
                                בחר מהרשימה
                            </option>
                            {categories
                                .filter(category => category && newCategoryType === 'SUB_CATEGORY')
                                .map(category =>
                                    category ? (
                                        <option key={category._id} value={category._id}>
                                            {category.name?.he}
                                        </option>
                                    ) : null,
                                )}
                        </select>
                    )}
                    <br />
                    <button onClick={updateCategoryNames}>Update עדכן🍌</button>

                    <button className="buttonA" onClick={addCategory}>
                        הוסף קטגוריה 🍍
                    </button>
                    <button className="buttonD" onClick={deleteCategory}>
                        הסתר קטגוריה 🍏
                    </button>
                    <button onClick={recoverCategoryOrSubCategory} className="buttonH">
                        שחזור קטגוריה 🍉
                    </button>
                </div>
            ) : (
                <p>לא נבחרה קטגוריה.</p>
            )}
        </div>
    );
};
export default ServicesCategoriesPanel;
