import { Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ZenatPlatform } from 'src/common/enums';
import { Loader } from 'src/components/loader/loader.component';
import { AreYouSureDialog } from 'src/dialogs';
import './manage-phone-versions.css';
import { ManagePhoneVersionsService } from './manage-phone-versions.service';

const ManagePhoneVersions = () => {
    const managePhoneVersions = new ManagePhoneVersionsService();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasUpdatedVersions, setHasUpdatedVersions] = useState<boolean>(false);
    const [isUpdatingMinVersionsInitiated, setIsUpdatingMinVersionsInitiated] = useState<boolean>(false);
    const [minVersion, setMinVersion] = useState([
        {
            platform: '',
            zenatPlatform: null,
            minimuimVersion: '',
        },
    ]);
    const [selectedZenatPlatform, setSelectedZenatPlatform] = useState(ZenatPlatform.ZENAT_MARKETPLACE);

    const onChangeBuild = (type: 'ios' | 'android' | 'web') => event => {
        setHasUpdatedVersions(true);
        if (type === 'ios') {
            setMinVersion(state =>
                state.map(version => {
                    if (version.zenatPlatform === selectedZenatPlatform && version.platform === 'ios') {
                        return {
                            ...version,
                            minimuimVersion: event.target.value,
                        };
                    }
                    return version;
                }),
            );
        } else if (type === 'android') {
            setMinVersion(state =>
                state.map(version => {
                    if (version.zenatPlatform === selectedZenatPlatform && version.platform === 'android') {
                        return {
                            ...version,
                            minimuimVersion: event.target.value,
                        };
                    }
                    return version;
                }),
            );
        } else {
            setMinVersion(state =>
                state.map(version => {
                    if (version.zenatPlatform === selectedZenatPlatform && version.platform === 'web') {
                        return {
                            ...version,
                            minimuimVersion: event.target.value,
                        };
                    }
                    return version;
                }),
            );
        }
    };

    useEffect(() => {
        const initMinimalVersions = async () => {
            try {
                const minVersions = await managePhoneVersions.getMinVersions();
                setMinVersion(minVersions);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        initMinimalVersions();
    }, []);

    const updateMinVersions = async (confirmed: boolean) => {
        if (!confirmed) {
            setIsUpdatingMinVersionsInitiated(false);
            return;
        }
        try {
            await managePhoneVersions.updateMinVersions(
                iosBuild.minimuimVersion,
                androidBuild.minimuimVersion,
                webBuild.minimuimVersion,
                selectedZenatPlatform,
            );
            setHasUpdatedVersions(false);
        } catch (error) {
            console.log(error);
        } finally {
            setIsUpdatingMinVersionsInitiated(false);
        }
    };

    const initiateUpdateMinVersions = () => {
        setIsUpdatingMinVersionsInitiated(true);
    };

    const iosBuild = useMemo(() => {
        return (
            minVersion?.find(
                version => version.zenatPlatform === selectedZenatPlatform && version.platform === 'ios',
            ) || { minimuimVersion: '1.0.0' }
        );
    }, [selectedZenatPlatform, minVersion]);

    const androidBuild = useMemo(() => {
        return (
            minVersion?.find(
                version => version.zenatPlatform === selectedZenatPlatform && version.platform === 'android',
            ) || { minimuimVersion: '1.0.0' }
        );
    }, [selectedZenatPlatform, minVersion]);

    const webBuild = useMemo(() => {
        return (
            minVersion?.find(
                version => version.zenatPlatform === selectedZenatPlatform && version.platform === 'web',
            ) || { minimuimVersion: '1.0.0' }
        );
    }, [selectedZenatPlatform, minVersion]);

    const onChange = event => {
        setSelectedZenatPlatform(event.target.value);
    };

    return (
        <>
            <AreYouSureDialog
                onClose={updateMinVersions}
                text={'האם אתה בטוח שברצונך לעדכן את גירסאות?'}
                okText={'OK'}
                cancelText={'CANCEL'}
                isVisible={isUpdatingMinVersionsInitiated}
            />
            <h1>עדכון גרסאות מערכות הפעלה לאפליקציית הטלפון</h1>
            <div className="table-contener">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <select onChange={onChange} defaultValue={selectedZenatPlatform}>
                            <option>{ZenatPlatform.HISHBON_MAHEER}</option>
                            <option>{ZenatPlatform.ZENAT_MARKETPLACE}</option>
                            <option>{ZenatPlatform.ZENAT_GROW}</option>
                        </select>

                        <div className="table-row">
                            <div className="platfrom-title">
                                <h3>ios Version build Number</h3>
                            </div>
                            <div className="input">
                                <input type="text" value={iosBuild?.minimuimVersion} onChange={onChangeBuild('ios')} />
                            </div>
                        </div>
                        <div className="table-row">
                            <div className="platfrom-title">
                                <h3>Android Version build Number</h3>
                            </div>
                            <div className="input">
                                <input
                                    type="text"
                                    value={androidBuild?.minimuimVersion}
                                    onChange={onChangeBuild('android')}
                                />
                            </div>
                        </div>
                        <div className="table-row">
                            <div className="platfrom-title">
                                <h3>Web Version build Number</h3>
                            </div>
                            <div className="input">
                                <input type="text" value={webBuild?.minimuimVersion} onChange={onChangeBuild('web')} />
                            </div>
                        </div>
                        <Button variant="contained" disabled={!hasUpdatedVersions} onClick={initiateUpdateMinVersions}>
                            Update עדכן🍌
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

export default ManagePhoneVersions;
